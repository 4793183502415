<template>
	<div>
		<a-spin :spinning="loading">
			<div>
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'taskType'">
							<div>{{ ['新手任务', '每日任务', '被动任务'][record.taskType - 1] }}</div>
						</template>
						<template v-if="column.key === 'type'">
							{{ ['次数限制', '年', '月', '日'][record.type] }}
						</template>
						<template v-if="column.key === 'split'">
							{{ record.type !== 0 ? record.split : '-' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							<div>{{ record.isDisabled ? '禁用' : '启用' }}</div>
						</template>
						<template v-if="column.key === 'icon'">
							<a-image :src="record.icon" style="width: 60px;"></a-image>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['user_task_list_edit']">
											<a-menu-item @click="onEdit(record)">
												编辑
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<a-modal v-model:visible="showModal" title="编辑任务" width="800px" @cancel="onCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="modelRef" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }">
					<a-form-item label="任务名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" placeholder="请输入"></a-input>
					</a-form-item>
					
					<a-form-item label="任务图标" name="icon" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="建议宽高比: 1:1">
						<a-upload v-model:file-list="fileList" list-type="picture-card" action="/admin/ajaxUpload.do"
							accept="image/*" @change="handleChange">
							<div v-if="fileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>
					
					<a-form-item label="周期类型" name="type" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-select v-model:value="modelRef.type" placeholder="请选择周期类型">
							<a-select-option :value="0">次数限制</a-select-option>
							<a-select-option :value="1">年</a-select-option>
							<a-select-option :value="2">月</a-select-option>
							<a-select-option :value="3">日</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item v-if="modelRef.type !== 0" label="周期间隔" name="split" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.split" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="次数" name="total" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.total" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="获得成长值" name="growth" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input-number v-model:value="modelRef.growth" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
					
					<a-form-item label="描述" name="description">
						<a-input v-model:value="modelRef.description" placeholder='请输入'></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getMemberTaskList,
		updateMemberTask,
	} from "@/service/modules/member";
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				showModal: false,
				loading: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '任务名称',
					dataIndex: 'name',
				}, {
					title: '任务类型',
					key: 'taskType',
				}, {
					title: '图标',
					key: 'icon'
				}, {
					title: '周期',
					key: 'type'
				}, {
					title: '周期间隔',
					key: 'split'
				}, {
					title: '次数',
					dataIndex: 'total'
				}, {
					title: '获得成长值',
					dataIndex: 'growth'
				}, {
					title: '描述',
					dataIndex: 'description'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				fileList: [],
				modelRef: {},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberTaskList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			handleChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.icon = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			onEdit(item) {
				if (item.icon) {
					this.fileList = [{
						uid: 1,
						url: item.icon,
						name: item.icon
					}]
				} else {
					this.fileList = [];
				}
				this.modelRef = JSON.parse(JSON.stringify(item));
				this.showModal = true;
			},
			onSubmit() {
				this.$refs.formRef.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					if (postData.type === 0) {
						delete postData.split;
					}
					this.loading = true;
					let ret = await updateMemberTask(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('编辑成功！');
						this.getData();
						this.onCancel();
					}
				})
			},
			onCancel() {
				this.$refs.formRef.resetFields();
				this.showModal = false;
				this.modelRef = {};
				this.fileList = [];
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>